import { NextRouter } from 'next/router';
import { Dispatch } from 'redux';
import { PAGE_ROUTES } from '~/constants/pages';
import { EXTERNAL_PAGE_ROUTES } from '~/constants/external-pages';
import { setNewRelicCustomAttribute } from '~/helpers/newrelic';
import {
  ActionTypes,
  AddressAction,
  UpdateAddressAction,
  Address,
  EligibilityAction,
  Eligibility,
  Promotion,
  PromotionsAction,
  ResetAddressRouteAction,
  AddressHistoryAction,
  ServiceabilityResponse,
  UnservReasonAction,
  SetVoiceEligibleAction,
  UnservRedirectAction,
} from '~/types/address';
import { ReduxState } from '~/types/redux';
import { setPageLoading } from './common';
import { setNotifications } from './global';
import { getProducts, setOfferType } from './products';
import isNurtureCampaign, { nurtureRedirect } from '~/helpers/checkNurtureCampaign';
import { routeToExternal } from '~/helpers/routeToExternal';
import { isFutureFiber } from '~/helpers/isFutureFiber';
import { trackEvent } from '~/helpers/tracking';
import Cookie from 'js-cookie';
import { CartConfigEditableLoader } from '~/types/editable-loader';

export const setAddress = (key: string, address: Address): AddressAction => {
  return { type: ActionTypes.SET_ADDRESS, key, address };
};

export const updateAddress = (key: string, address: Address): UpdateAddressAction => {
  return { type: ActionTypes.UPDATE_ADDRESS, key, address };
};

export const setEligibility = (eligibility: Eligibility): EligibilityAction => {
  return { type: ActionTypes.SET_ELIGIBILITY, eligibility };
};

export const setPromotions = (promotions: Promotion[]): PromotionsAction => {
  return { type: ActionTypes.SET_PROMOTIONS, promotions };
};

export const resetAddressRoute = (): ResetAddressRouteAction => {
  return { type: ActionTypes.RESET_ADDRESS_ROUTE };
};

export const addAddressHistory = (addressKey: string): AddressHistoryAction => {
  return { type: ActionTypes.ADDRESS_HISTORY, addressKey };
};

export const setUnservReason = (unservReason: string): UnservReasonAction => {
  return { type: ActionTypes.SET_UNSERV_REASON, unservReason };
};

export const setUnservRedirect = (unservRedirect: Record<string, string>): UnservRedirectAction => {
  return { type: ActionTypes.SET_UNSERV_REDIRECT, unservRedirect };
};

export const setVoiceEligible = (voiceEligible: boolean): SetVoiceEligibleAction => {
  return { type: ActionTypes.SET_VOICE_ELIGIBLE, voiceEligible };
};

const handleBasicData = (dispatch: Dispatch, response: ServiceabilityResponse) => {
  const { voiceEligible, unservReason } = response;
  Cookie.set('voiceEligible', Boolean(voiceEligible));
  if (voiceEligible) dispatch(setVoiceEligible(voiceEligible));
  if (unservReason) dispatch(setUnservReason(unservReason));
};

const handleDMAData = (dispatch: Dispatch, response: ServiceabilityResponse) => {
  const { designatedMarketArea, designatedMarketAreaDescription } = response;
  if (designatedMarketArea) {
    dispatch(updateAddress('install', { dma: designatedMarketArea }));
    Cookie.set('dma', designatedMarketArea);
  }
  if (designatedMarketAreaDescription) {
    dispatch(updateAddress('install', { dmaName: designatedMarketAreaDescription }));
    Cookie.set('dmaName', designatedMarketAreaDescription);
  }
};

const handleUnserviceableRedirect = (
  dispatch: Dispatch,
  unservRedirect: Record<string, string> | undefined,
  isFutureFiberEligible: boolean,
  loaderMetadata?: CartConfigEditableLoader
): boolean => {
  if (unservRedirect?.provider && !isFutureFiberEligible) {
    dispatch(setUnservRedirect(unservRedirect));
    Cookie.set('unservRedirectProvider', unservRedirect?.provider ?? '');
    return Boolean(loaderMetadata?.unservRedirectEnabled);
  }
  return false;
}

export const clearServiceabilityResults = () => {
  return (dispatch: Dispatch): void => {
    dispatch(resetAddressRoute());
    dispatch(setUnservReason(''));
  };
};

export const setServiceabilityResults = ({
  apiVersion,
  response,
  router,
  loaderMetadata,
  monarch,
}: {
  response: ServiceabilityResponse;
  router: NextRouter;
  apiVersion: string;
  monarch?: ReduxState['monarch'];
  loaderMetadata?: CartConfigEditableLoader;
}) => {
  return (dispatch: Dispatch, getState: () => ReduxState): void => {
    const {
      success = false,
      offerType = '',
      useUnserveSoft = false,
      addressHasExistingService = false,
      userErrorMessage = '',
      addressKey = '',
      env = '',
      controlNumber = '',
      inFootprint = true, // default to true to avoid unserviceable redirect path
      fiberBuildOutStatus = '',
      futureServiceDate = '',
      unservRedirect = undefined,
      tipData = null,
    } = response;

    // Handle basic data and cookies
    handleBasicData(dispatch, response);
    handleDMAData(dispatch, response);
    if (tipData) {
      Cookie.set('tipDataFromZipCode', JSON.stringify(tipData));
    }

    const isMovers = getState().monarch?.cartMovers;
    const isFutureFiberEligible = isFutureFiber(fiberBuildOutStatus, futureServiceDate);

    // Early return if unserviceable redirect is enabled
    if (handleUnserviceableRedirect(dispatch, unservRedirect, isFutureFiberEligible, loaderMetadata)) {
      return;
    }

    // Route handling based on serviceability results
    if (useUnserveSoft) {
      isNurtureCampaign(router.query) ? nurtureRedirect() : router.push(PAGE_ROUTES.UNSERVICEABLE_SOFT);
    } else if (inFootprint === false) {
      isNurtureCampaign(router.query) ? nurtureRedirect() : router.push(PAGE_ROUTES.UNSERVICEABLE_ALLCONNECT);
    } else if (fiberBuildOutStatus === 'PAL_NEEDED') {
      const route =
        process.env.environment === 'production'
          ? EXTERNAL_PAGE_ROUTES.PAL_NEEDED_PROD
          : EXTERNAL_PAGE_ROUTES.PAL_NEEDED_QA;
      isNurtureCampaign(router.query) ? nurtureRedirect() : routeToExternal(route, env, controlNumber);
    } else if (isFutureFiberEligible) {
      trackEvent({
        action: 'elementClicked',
        data: {
          elementType: 'REDIRECT',
          position: 'WHERES_MY_FIBER',
          location: 'WHERES_MY_FIBER',
          action: 'EXTERNAL_LINK',
        },
      });
      routeToExternal(EXTERNAL_PAGE_ROUTES.WHERES_MY_FIBER, env, controlNumber);
    } else if (success) {
      if (addressKey) {
        dispatch(updateAddress('install', { addressKey, env, controlNumber }));
      }
      if (offerType) {
        dispatch(setOfferType(offerType));
      }
      if (addressHasExistingService) {
        dispatch(setEligibility({ isMovers: false, addressHasExistingService, serviceable: false }));
      } else if (isMovers) {
        dispatch(setEligibility({ isMovers: true, addressHasExistingService, serviceable: true }));
      } else {
        getProducts({
          router,
          isCopper2Fiber: false,
          apiVersion,
          cartPage: '',
          monarch,
          env,
          controlNumber,
        })(dispatch, getState);
      }
    } else {
      // Handle error case
      dispatch(setPageLoading(false));
      dispatch(
        setNotifications([
          {
            type: 'error',
            message:
              userErrorMessage ||
              "Sorry, we're having some technical difficulties. Call ##phoneNumber## and one of our sales experts will help you complete your order.",
          },
        ])
      );
      setNewRelicCustomAttribute([{ name: 'serviceabilityError', value: 'true' }]);
    }
  };
};
